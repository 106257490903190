//Header scoroll
$(window).scroll(function() {
    var scroll = $(window).scrollTop();
     //>=, not <=
    if (scroll >= 500) {
        //clearHeader, not clearheader - caps H
        $(".header_wrapper").addClass("pinned");
    }else{
      $(".header_wrapper").removeClass("pinned");
    }
}); //missing );

/*Open Menu*/
$(".menu_button").on('click', function() {
  if($(this).hasClass('is-active')){
      $("body").css('overflow-y','scroll');
      $(this).removeClass('is-active');
      $('.mobile_menu').removeClass('is-active');
      $('.header_wrapper').removeClass('openmenu');
    }
    else{
      $(this).addClass('is-active');
      jQuery("body").css('overflow-y','hidden');
      $('.mobile_menu').addClass('is-active');
      $('.header_wrapper').addClass('openmenu');
  }
});




/*Hover Menu - Add Class to all except hover item*/
// $(document).ready(function() {
//   $('#menu-main-menu li a').hover(function() {
//     $('#menu-main-menu li a').not($(this)).addClass('not-hovered');
//   }, function() {
//     $('#menu-main-menu li a').removeClass('not-hovered');
//   });
// });




/* Default slider swipper */
//import Swiper, { Navigation , Pagination } from 'swiper';
if (window.location.hash) {
    var hash = window.location.hash;

    if ($(hash).length) {
        $('html, body').animate({
            scrollTop: $(hash).offset().top -150
        }, 900, 'swing');
    }
}
